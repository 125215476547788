import React, { useEffect, useState } from 'react';
import { Authorizer, Footer, GoBack } from 'components';
import { useHistory} from 'react-router-dom';
import { Table, Spin, message, Pagination  } from 'antd';
import { agentService } from 'services';
import { FamilyVisitsPerAgent,  } from 'types'; 
import { Breadcrumb } from 'components/Articulator/Breadcrumb'; 

export function ArticulatorFamiliesNotVisited() {
  const history = useHistory();
  const { useAuth } = Authorizer;
  const auth = useAuth();
  const userId = auth.user?.client?.userId;
  const [familiesVisits, setFamiliesVisits] = useState<FamilyVisitsPerAgent[]>([]); 
  const [familiesNotVisitedLoading, setFamiliesNotVisitedLoading] = useState(false);


  const breadcrumbItems = [
    {
      id: 1,
      to: "/articulador-inicio",
      title: "Página inicial"
    },
    {
      id: 2,
      to: "/articulador-familias-nao-visitadas",
      title: "Famílias Não Visitadas"
    }
  ];

  function handleGoBack(){
    return history.push('/articulador-inicio');
  }

  async function handleGetAgent() {
    const response = await agentService.getProfessional(userId);
    return response;
  }

  function removerAcentos(palavra) {
    const mapaAcentosHex = {
      a: /[áàâã]/g,
      e: /[éèê]/g,
      i: /[íì]/g,
      o: /[óòô]/g,
      u: /[úùû]/g,
      c: /[ç]/g,
      n: /[ñ]/g,
      A: /[ÁÀÂÃ]/g,
      E: /[ÉÈÊ]/g,
      I: /[ÍÌ]/g,
      O: /[ÓÒÔ]/g,
      U: /[ÚÙÛ]/g,
      C: /[Ç]/g,
      N: /[Ñ]/g,
    };
  
    for (let letra in mapaAcentosHex) {
      const expressaoRegular = mapaAcentosHex[letra];
      palavra = palavra.replace(expressaoRegular, letra);
    }
  
    return palavra;
  }

  const handleDataFamiliesPerAgent = async () => {
    const agent = await handleGetAgent();
    const agentCity = removerAcentos(agent.city.toUpperCase());
    const response = await agentService.getFamiliesNotVisiteds(agentCity);
    return response;
  };


  async function getDataFamilies() {
    setFamiliesNotVisitedLoading(true);
    await handleDataFamiliesPerAgent()
      .then(res => 
        setFamiliesVisits(res))
    
    setFamiliesNotVisitedLoading(false);
  }

  useEffect(() => {
    try{
      getDataFamilies();
    }catch(err){
      message.error('Erro a carregar famílias visitas do agente');
    }
  }, []);


  const columns = [
    {
      title: 'NIS',
      dataIndex: 'nis',
      key: "nis"
    },
    {
      title: 'Nome da Responsável Familiar',
      dataIndex: 'nameRF',
      key: 'nameRF'
    },
    {
      title: 'Bairro',
      dataIndex: 'neighborhood',
      key: "neighborhood"
    },
    {
      title: 'Logradouro',
      dataIndex: 'addressStreet',
      key: "addressStreet"
    },
    {
      title: 'Número',
      dataIndex: 'addressNumber',
      key: "addressNumber"
    }
  ];

  return (
    <>

    <div style={{padding: 20, marginLeft: 20}}>
        <Breadcrumb 
          items={breadcrumbItems}
        />
    </div>
    <div style={{padding: 20, marginBottom:100}}>
      <GoBack 
          onClick={handleGoBack}
          title="Famílias Não Visitadas Ativas"
          subtitle="Lista de Famílias Ativas Não Visitadas"
      />
    </div>
    {/* <div style={{display: 'flex', flexDirection:'row'}} >
      <div style={{background: '#9bc75f', width: '300px', height: '100px', margin: '10px', borderRadius: '10px'}}>
            <p style={{fontWeight : 'bold', textAlign : 'center', marginTop: '20px', marginBottom:'0px'}}> NÃO VISITADAS </p>
            <p style={{fontWeight : 'bold', textAlign : 'center', fontSize: '20px'}}>{familiesVisits.length ? familiesVisits.length : ''}</p>
      </div>
    </div> */}
    <div style={{marginBottom: 100, marginLeft: 20, marginRight: 20}}>

      <h1 style={{textAlign: 'center', margin: '0 auto', fontWeight: 'bold', marginBottom: '20', backgroundColor: '#D3D3D3'}}> PÁGINA EM MANUTENÇÃO</h1> 
      <br></br>
      <h2 style={{textAlign: 'center', margin: '10 auto'}}>
          Nesse período no período de 11 a 20 de setembro, permanecer no CRAS, contribuindo com as famílias CMIC.
          Enviar relatório das atividades realizadas dos dias 11 até 20 de setembro, 
          por email: agentes.maisinfancia@sps.ce.gov.br
      </h2>
      <br></br>
      <h2 style={{textAlign: 'center', margin: '0 auto' }}> RETORNO PREVISTO PARA O DIA 23/09/2024 </h2>

      {/* {!familiesVisits ? ("Sem famílias Encontradas/Visitadas") : (
          <Table dataSource={familiesVisits} columns={columns}
          loading={familiesNotVisitedLoading}/>
        )
      } */}
    </div>
    
    <Footer />

    </>
  )
}
